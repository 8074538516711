import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  QUESTIONS_BANK,
  QUESTIONS_BANK_ADD_TEACHERS,
  QUESTIONS_BANK_ALL,
  QUESTIONS_BANK_CREATE,
  QUESTIONS_BANK_DELETE,
  QUESTIONS_BANK_LIST,
  QUESTIONS_BANK_REMOVE_TEACHERS,
} from '../constants/state'

export const questionsBankSelector = createSelector(path([GLOBAL_STATE, QUESTIONS_BANK]), data => data)

export const questionsBankListSelector = createSelector(path([GLOBAL_STATE, QUESTIONS_BANK_LIST]), data => data)

export const questionsBankAllSelector = createSelector(path([GLOBAL_STATE, QUESTIONS_BANK_ALL]), data => data)

export const questionsBankCreateSelector = createSelector(path([GLOBAL_STATE, QUESTIONS_BANK_CREATE]), data => data)

export const questionsBankDeleteSelector = createSelector(path([GLOBAL_STATE, QUESTIONS_BANK_DELETE]), data => data)

export const questionsBankAddTeacherSelector = createSelector(
  path([GLOBAL_STATE, QUESTIONS_BANK_ADD_TEACHERS]),
  data => data
)

export const questionsBankRemoveTeacherSelector = createSelector(
  path([GLOBAL_STATE, QUESTIONS_BANK_REMOVE_TEACHERS]),
  data => data
)

export const questionsBankSelectors = {
  questionsBankSelector,
  questionsBankListSelector,
  questionsBankAllSelector,
  questionsBankCreateSelector,
  questionsBankDeleteSelector,
  questionsBankAddTeacherSelector,
  questionsBankRemoveTeacherSelector,
}
