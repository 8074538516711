import { path } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, LESSON, LESSON_ALL, LESSON_CREATE, LESSON_LIST } from '../constants/state'

export const lessonSelector = createSelector(path([GLOBAL_STATE, LESSON]), data => data)

export const lessonListSelector = createSelector(path([GLOBAL_STATE, LESSON_LIST]), data => data)

export const lessonAllSelector = createSelector(path([GLOBAL_STATE, LESSON_ALL]), data => data)

export const lessonCreateSelector = createSelector(path([GLOBAL_STATE, LESSON_CREATE]), data => data)

export const lessonSelectors = {
  lessonSelector,
  lessonListSelector,
  lessonAllSelector,
  lessonCreateSelector,
}
