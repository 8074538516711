import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACCOUNT,
  ACCOUNT_ALL,
  ACCOUNT_LIST,
  ACCOUNT_PASSWORD_UPDATE,
  ACCOUNT_PROFILE_UPDATE,
  GLOBAL_STATE,
} from '../constants/state'

export const accountSelector = createSelector(path([GLOBAL_STATE, ACCOUNT]), data => data)

export const accountListSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_LIST]), data => data)

export const accountAllSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_ALL]), data => data)

export const accountProfileUpdateSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_PROFILE_UPDATE]), data => data)

export const accountPasswordUpdateSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_PASSWORD_UPDATE]), data => data)

export const accountSelectors = {
  accountSelector,
  accountListSelector,
  accountAllSelector,
  accountProfileUpdateSelector,
  accountPasswordUpdateSelector,
}
