import { dissoc, prop } from 'ramda'

import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'

export const academicYear = data =>
  getInstance().get(`${API.ACADEMIC_YEAR}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const academicYearCreate = data => getInstance().post(API.ACADEMIC_YEAR, data)

export const academicYearDelete = data => getInstance().delete(`${API.ACADEMIC_YEAR}${prop('id', data)}`)

export const academicYearList = data =>
  getInstance().get(API.ACADEMIC_YEAR, {
    params: data,
  })

export const academicYearAll = data =>
  getInstance().get(API.ACADEMIC_YEAR_ALL, {
    params: data,
  })
