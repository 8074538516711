import moment from 'moment'
import { defaultTo, equals, forEach, includes, join, lensProp, map, over, path, pipe, prop, propOr, type } from 'ramda'

import {
  GENERAL_EVENT,
  TEACHING_WEEK,
  calendarEventTypeOptions,
  calendarExtracurricularActivitiesTypes,
} from '../constants/types/calendarEventTypes'
import * as USER_ROLE from '../constants/userRoles'

export const getDate = pipe(item => moment(item).format('DD.MM.YYYY'))

export const getDateEuropeFormat = pipe(item => moment(item).format('MM.DD.YYYY'))

export const getDateUSA = pipe(item => moment(item).format())

export const getTime = pipe(item => moment(item).format('HH:mm'))

export const getDateTime = pipe(item => (item ? moment(item).format('YYYY-MM-DD HH:mm') : undefined))

export const getDateTimeFull = pipe(item => moment(item).format("YYYY-MM-DD'T'HH:mm:ss.SSSZ"))

export const parseDate = pipe(item => (item ? moment(item).valueOf() : null))

const calculateTotalPagesByCount = list => {
  const pageable = prop('pageable', list)
  const pageSize = prop('pageSize', pageable)
  const totalElements = prop('total_elements', list)
  return Math.ceil(totalElements / pageSize)
}

export const getPage = data => ({
  ...data,
  page: prop('page', data) ? +prop('page', data) : 0,
  size: defaultTo(10)(prop('size', data)),
})

export const getQuery = data => ({
  ...data,
  page: prop('page', data) ? +prop('page', data) : 1,
  sort: prop('sort', data) ? decodeURIComponent(prop('sort', data)) : undefined,
})

export const getDataFromState = (name, type) => store => ({
  data: path([type, name, 'data'], store),
  loading: path([type, name, 'loading'], store),
  error: path([type, name, 'error'], store),
  failed: path([type, name, 'failed'], store),
})

export const getPageableList = list => ({
  content: propOr([], 'content', list),
  topContent: propOr([], 'top_content', list),
  size: prop('size', list),
  pageSize: path(['pageable', 'pageSize'], list) || 10,
  totalElements: prop('total_elements', list),
  totalPages: prop('total_pages', list) || calculateTotalPagesByCount(list),
  last: prop('last', list),
})

export const getSelectValue = value => {
  if (equals(type(value), 'Object')) {
    return prop('value', value)
  }

  if (equals(type(value), 'Array')) {
    return map(prop('value'), value || [])
  }
}

export const joinByKey = key => over(lensProp(key), join(','))

export function getEventOptions(userInfo) {
  const optArr = []

  forEach(role => {
    switch (role) {
      case USER_ROLE.SUPER_ADMIN:
      case USER_ROLE.DEMO_ADMIN:
        forEach(option => {
          !includes(option, optArr) && optArr.push(option)
        })([
          ...calendarEventTypeOptions,
          { value: TEACHING_WEEK, label: 'Teaching Week' },
          ...calendarExtracurricularActivitiesTypes,
        ])
        break
      case USER_ROLE.QUALITY_ASSURANCE:
        forEach(option => {
          !includes(option, optArr) && optArr.push(option)
        })(calendarEventTypeOptions)
        break
      case USER_ROLE.PROGRAMME_MANAGER:
      case USER_ROLE.TEACHING_AND_LEARNING_OFFICER:
        forEach(option => {
          !includes(option, optArr) && optArr.push(option)
        })([{ value: TEACHING_WEEK, label: 'Teaching Week' }])
        break
      case USER_ROLE.EXTRACURRICULAR_ACTIVITY_MANAGER:
      case USER_ROLE.AR_COMMITTEE:
        forEach(option => {
          !includes(option, optArr) && optArr.push(option)
        })(calendarExtracurricularActivitiesTypes)
        break
      default:
        forEach(option => {
          !includes(option, optArr) && optArr.push(option)
        })([{ value: GENERAL_EVENT, label: 'General Event' }])
    }
  })(propOr([], 'roles', userInfo))

  return optArr
}
