import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const studentAssessment = data =>
  instance.get(API.STUDENT_ASSESSMENT_INFO, {
    params: data,
  })

export const studentAssessmentResult = data => instance.get(API.STUDENT_ASSESSMENT_RESULT, { params: data })

export const studentAssessmentSave = data => instance.post(API.STUDENT_ASSESSMENT_SAVE, data)

export const studentAssessmentSubmit = data => instance.post(API.STUDENT_ASSESSMENT_SUBMIT, data)

export const studentAssessmentSelect = data => instance.get(API.STUDENT_ASSESSMENT_SELECT, { params: data })
