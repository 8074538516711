import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT,
  ASSESSMENT_ALL,
  ASSESSMENT_CREATE,
  ASSESSMENT_DELETE,
  ASSESSMENT_GROUPS_IMPORT_BY_STUDENT,
  ASSESSMENT_LIST,
  ASSESSMENT_RESULTS,
  ASSESSMENT_TYPES,
  GLOBAL_STATE,
} from '../constants/state'

export const assessmentSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT]), data => data)

export const assessmentCreateSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_CREATE]), data => data)

export const assessmentListSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_LIST]), data => data)

export const assessmentDeleteSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_DELETE]), data => data)

export const assessmentAllSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_ALL]), data => data)

export const assessmentTypesSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_TYPES]), data => data)

export const assessmentResultsSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_RESULTS]), data => data)

export const assessmentGroupsImportByStudentSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_GROUPS_IMPORT_BY_STUDENT]),
  data => data
)
