import { dissoc, prop } from 'ramda'

import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'

export const teachingGroupList = data =>
  getInstance().get(API.TEACHING_GROUP, {
    params: data,
  })

export const teachingGroupCreate = data => getInstance().post(API.TEACHING_GROUP, data)

export const teachingGroupDelete = data => getInstance().delete(`${API.TEACHING_GROUP}${prop('id', data)}`)

export const teachingGroupGetById = data =>
  getInstance().get(`${API.TEACHING_GROUP}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const teachingGroupAll = data =>
  getInstance().get(API.TEACHING_GROUP_ALL, {
    params: dissoc('cancelToken', data),
    cancelToken: prop('cancelToken', data),
  })

export const teachingGroupGrades = data =>
  getInstance().get(API.TEACHING_GROUP_GRADES, {
    params: data,
  })

export const teachingGroupStudents = data => getInstance().post(API.TEACHING_GROUP_STUDENTS, data)

export const teachingGroupTeachers = data => getInstance().post(API.TEACHING_GROUP_TEACHERS, data)

export const teachingGroupTypes = data =>
  getInstance().get(API.TEACHING_GROUP_TYPES, {
    params: data,
  })
