import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  QUESTION,
  QUESTION_ALL,
  QUESTION_CREATE,
  QUESTION_DELETE,
  QUESTION_LIST,
  QUESTION_TYPES,
} from '../constants/state'

export const questionSelector = createSelector(path([GLOBAL_STATE, QUESTION]), data => data)

export const questionListSelector = createSelector(path([GLOBAL_STATE, QUESTION_LIST]), data => data)

export const questionAllSelector = createSelector(path([GLOBAL_STATE, QUESTION_ALL]), data => data)

export const questionCreateSelector = createSelector(path([GLOBAL_STATE, QUESTION_CREATE]), data => data)

export const questionDeleteSelector = createSelector(path([GLOBAL_STATE, QUESTION_DELETE]), data => data)

export const questionTypesSelector = createSelector(path([GLOBAL_STATE, QUESTION_TYPES]), data => data)
