import { parse } from 'qs'
import { equals, map, path, pathOr, pipe, prop, propOr, reverse, split, type } from 'ramda'
import { toast } from 'react-toastify'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { ErrorToast, SuccessToast } from '../components/Toasts'
import * as stateSelectors from '../redux/selector'
import { getPage } from './get'
import { isModeratorViewer } from './isUser'

export function* getPageSaga({ action }) {
  const { query } = yield select(stateSelectors.routerSelector)
  const get = getPage(query)

  yield put(action(get))
}

export function* watchSaga({ action, api, serialize }) {
  yield takeEvery(action.pending, function* (fullPayload) {
    const { payload } = fullPayload
    try {
      const userInfo = yield select(stateSelectors.authUserInfo)
      const isCreate = pipe(split('_'), reverse, item => equals(item[1], 'CREATE'))(action.pending)
      const isDelete = pipe(split('_'), reverse, item => equals(item[1], 'DELETE'))(action.pending)

      if (isModeratorViewer(userInfo) && (isCreate || isDelete)) {
        toast.error(<ErrorToast text='User has no access' />)

        yield put({
          type: action.rejected,
          payload: new Error('user role'),
        })
      } else {
        const serializeType = type(serialize)
        const params = equals(serializeType, 'Function') ? serialize : payload
        // const filteredPayload = dissoc('fn', params)
        const filteredPayload = {}
        for (const key in params) {
          // eslint-disable-next-line no-prototype-builtins
          if (params.hasOwnProperty(key)) {
            if (type(params[key]) !== 'Function') {
              filteredPayload[key] = params[key]
            }
          }
        }
        const response = yield call(api, filteredPayload)
        const getResponse = propOr(() => {}, 'getResponse', fullPayload)
        getResponse(response)
        const { data } = response
        if (prop('setDataToState', payload)) {
          const setState = propOr(() => {}, 'setState', payload)
          const key = path(['setDataToState', 'key'], payload)
          yield put({
            type: action.fulfilled,
          })
          yield setState(prevState => ({
            ...prevState,
            [key]: data,
          }))
        } else {
          yield put({
            type: action.fulfilled,
            payload: data,
            args: payload,
          })
        }
      }
    } catch (err) {
      const data = prop('data', err)
      yield put({
        type: action.rejected,
        payload: data,
        args: payload,
      })
    }
  })
}

export function* watchSagaFulfilled({
  action,
  message,
  dispatchActions,
  dispatchApi,
  hasParams = false,
  shouldCloseModal = false,
  shouldOpenModal = false,
  shouldSetNewItem = false,
  autoDownloadParams,
  filter,
  shouldDoFulfilledFunction = false,
}) {
  yield takeEvery(action.fulfilled, function* (payload) {
    const title = prop('title', message)
    const description = prop('description', message)
    const { location } = yield select(stateSelectors.routerSelector)
    const queryIds = parse(location.search, { ignoreQueryPrefix: true })

    if (type(dispatchApi) === 'Object') {
      yield call(dispatchApi)
    }

    if (type(dispatchApi) === 'Array') {
      yield all(map(item => call(item), dispatchApi))
    }
    if (shouldDoFulfilledFunction) {
      const fn = pathOr(() => {}, ['args', 'fn'], payload)
      const response = prop('payload', payload)
      fn(response)
    }

    if (type(dispatchActions) === 'Object') {
      if (hasParams) {
        yield put(dispatchActions)
      } else yield put(dispatchActions)
    } else if (type(dispatchActions) === 'Function') {
      yield put(
        dispatchActions({
          id: path(['args', 'id'], payload),
          fn: path(['args', 'setNewItem'], payload),
          shouldDoFulfilledFunction: true,
        })
      )
    }

    if (type(dispatchActions) === 'Array') {
      yield all(map(item => put(item), dispatchActions))
    }

    if (message) {
      if (message.alert) {
        const alert = pathOr(() => {}, ['args', 'alert'], payload)
        alert()
      } else {
        toast.success(<SuccessToast text={description} />)
      }
    }

    if (shouldCloseModal) {
      const close = path(['args', 'close'], payload)
      close()
    }

    if (shouldOpenModal) {
      const open = path(['args', 'openModal'], payload) || (() => {})
      open(undefined, prop('payload', payload))
    }

    if (autoDownloadParams) {
      const url = path(['payload', ...prop('path', autoDownloadParams)], payload)
      if (equals(type(url), 'Array')) {
        url.forEach(singleUrl => {
          let counter = 0
          window.open(singleUrl, '_blank', `PopUp${++counter}…`)
        })
      } else if (equals(type(url), 'String')) {
        window.open(url)
      }
    }

    if (shouldSetNewItem) {
      const setNewItem = path(['args', 'setNewItem'], payload) || (() => {})
      setNewItem(prop('payload', payload))
    }

    if (filter) {
      if (prop('takeActionFromPayload', filter)) {
        const filterAction = path(['args', 'filterAction'], payload) || (() => {})
        yield put(filterAction(getPage(queryIds)))
      } else {
        const { filterAction } = filter
        const params = propOr({}, 'params', filter)
        yield put(filterAction(getPage({ ...queryIds, ...params })))
      }
    }
  })
}

export function* watchSagaRejected({ action, message, dispatchAction = () => {} }) {
  yield takeEvery(action.rejected, function (action) {
    const payload = prop('payload', action)
    const args = prop('args', action)
    const onRejected = propOr(() => {}, 'onRejected', args)
    const title = prop('error', payload)
    const description = prop('message', payload)

    onRejected(action)
    dispatchAction()

    if (message) {
      toast.error(<ErrorToast text={description} />)
    }
  })
}

export class WatchSaga {
  actionType = {
    fulfilled: '',
    rejected: '',
    pending: '',
  }
  api = () => {}

  constructor({ actionType, api }) {
    this.actionType = actionType
    this.api = api
    this.worker = this.worker.bind(this)
    this.watch = this.watch.bind(this)
    this.watchFulfilled = this.watchFulfilled.bind(this)
    this.watchRejected = this.watchRejected.bind(this)
  }

  *watch(params = {}) {
    yield takeLatest(this.actionType.pending, this.worker)
  }

  *watchFulfilled() {
    yield takeEvery(this.actionType.fulfilled, function* (action) {
      const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
      const onFinally = pathOr(() => {}, ['args', 'onFinally'], action)
      yield call(onFulfilled, action.payload)
      yield call(onFinally, action.payload)
    })
  }

  *watchRejected() {
    yield takeEvery(this.actionType.rejected, function* (action) {
      const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
      const onFinally = pathOr(() => {}, ['args', 'onFinally'], action)
      yield call(onRejected, action.payload)
      yield call(onFinally, action.payload)
      toast.error(<ErrorToast text={action?.payload?.message} />)
    })
  }

  *worker(action) {
    try {
      if (typeof action.payload === 'function') {
        return yield call(action.payload, { api: this.api, actionType: this.actionType })
      }
      const serialized = JSON.parse(JSON.stringify(action.payload || {}))
      const { data } = yield call(this.api, serialized)

      yield put({
        type: this.actionType.fulfilled,
        payload: data,
        args: action.payload,
      })
    } catch (error) {
      console.log('error', error)

      const data = prop('data', error) || path(['response', 'data'], error)
      yield put({
        type: this.actionType.rejected,
        payload: data,
        args: action.payload,
      })
    }
  }
}
