import { path } from 'ramda'
import { createSelector } from 'reselect'

export const navbarSelector = createSelector(path(['navbar']), data => data)

export const navbarParentSelector = createSelector(path(['navbar', 'parent']), data => data)

export const navbarSelectors = {
  navbarSelector,
  navbarParentSelector,
}
