import { dissoc, prop } from 'ramda'

import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'

export const student = data =>
  getInstance().get(`${API.STUDENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const studentCreate = data => getInstance().post(API.STUDENT, data)

export const studentDelete = data => getInstance().delete(`${API.STUDENT}${prop('id', data)}`)

export const studentList = data =>
  getInstance().get(API.STUDENT, {
    params: data,
  })

export const studentAchievementRecords = data =>
  getInstance().get(API.STUDENT_ACHIEVEMENT_RECORDS, {
    params: data,
  })

export const studentAll = data =>
  getInstance().get(API.STUDENT_ALL, {
    params: data,
  })

export const studentGuard = data =>
  getInstance().get(API.STUDENT_GUARD, {
    params: data,
  })

export const studentGuardCreate = data => getInstance().post(API.STUDENT_GUARD, data)

export const studentUsername = data =>
  getInstance().get(`${API.STUDENT_USERNAME}${prop('username', data)}`, {
    params: dissoc('username', data),
  })

export const generateTranscript = data => getInstance().post(API.GENERATE_TRANSCRIPT, data)

export const studentPersonalInfo = data => getInstance().put(API.STUDENT_PERSONAL_INFO, data)

export const studentContactInfo = data => getInstance().put(API.STUDENT_CONTACT_INFO, data)

export const updateStudentStatus = data => getInstance().put(API.STUDENT_STATUS, data)

export const studentDashboard = data => getInstance().get(API.STUDENT_DASHBOARD, data)
