import { pathOr } from 'ramda'
import { all, fork, takeEvery } from 'redux-saga/effects'

import { WatchSaga, getPageSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '../../../helpers/customSaga'
import * as actions from '../../../redux/actions'
import * as API from '../api'
import actionTypes, {
  QUESTION,
  QUESTION_ALL,
  QUESTION_CREATE,
  QUESTION_DELETE,
  QUESTION_LIST,
  QUESTION_TYPES,
} from '../constants/actionTypes'

function watchQuestion() {
  return watchSaga({
    action: actionTypes[QUESTION],
    api: API.questionGetById,
  })
}

function watchQuestionRejected() {
  return watchSagaRejected({
    action: actionTypes[QUESTION],
    message: true,
  })
}

function watchQuestionList() {
  return watchSaga({
    action: actionTypes[QUESTION_LIST],
    api: API.questionList,
  })
}

function watchQuestionListRejected() {
  return watchSagaRejected({
    action: actionTypes[QUESTION_LIST],
    message: true,
  })
}

const questionAllSaga = new WatchSaga({ actionType: actionTypes[QUESTION_ALL], api: API.questionAll })

const questionCreateSaga = new WatchSaga({ actionType: actionTypes[QUESTION_CREATE], api: API.questionCreate })

function watchQuestionDelete() {
  return watchSaga({
    action: actionTypes[QUESTION_DELETE],
    api: API.questionDelete,
  })
}

function* watchQuestionDeleteFulfilled() {
  yield takeEvery(actionTypes[QUESTION_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    // yield getPageSaga(actions.questionList)
  })
}

function* watchQuestionDeleteRejected() {
  yield takeEvery(actionTypes[QUESTION_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

const questionTypeSaga = new WatchSaga({ actionType: actionTypes[QUESTION_TYPES], api: API.questionTypes })

export function* questionSaga() {
  yield all([
    fork(watchQuestion),
    fork(watchQuestionRejected),
    fork(watchQuestionList),
    fork(watchQuestionListRejected),
    fork(questionAllSaga.watch),
    fork(questionAllSaga.watchFulfilled),
    fork(questionAllSaga.watchRejected),
    fork(questionCreateSaga.watch),
    fork(questionCreateSaga.watchFulfilled),
    fork(questionCreateSaga.watchRejected),
    fork(watchQuestionDelete),
    fork(watchQuestionDeleteFulfilled),
    fork(watchQuestionDeleteRejected),
    fork(questionTypeSaga.watch),
    fork(questionTypeSaga.watchFulfilled),
    fork(questionTypeSaga.watchRejected),
  ])
}
