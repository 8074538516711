import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  STUDENT_ASSESSMENT,
  STUDENT_ASSESSMENT_SAVE,
  STUDENT_ASSESSMENT_SUBMIT,
} from '../constants/state'

export const studentAssessmentSelector = createSelector(path([GLOBAL_STATE, STUDENT_ASSESSMENT]), data => data)

export const studentAssessmentSaveSelector = createSelector(path([GLOBAL_STATE, STUDENT_ASSESSMENT_SAVE]), data => data)

export const studentAssessmentSubmitSelector = createSelector(
  path([GLOBAL_STATE, STUDENT_ASSESSMENT_SUBMIT]),
  data => data
)

export const examSelectors = {
  studentAssessmentSelector,
  studentAssessmentSaveSelector,
  studentAssessmentSubmitSelector,
}
