import { dissoc, prop } from 'ramda'

import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'

export const lesson = data =>
  getInstance().get(`${API.LESSON}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const lessonCreate = data => getInstance().post(API.LESSON, data)

export const lessonDelete = data => {
  const query = prop('questions_bank_id', data)
    ? `${API.LESSON}${prop('id', data)}?questions_bank_id=${prop('questions_bank_id', data)}`
    : `${API.LESSON}${prop('id', data)}`
  return getInstance().delete(query)
}

export const lessonList = data =>
  getInstance().get(API.LESSON, {
    params: data,
  })

export const lessonAll = data =>
  getInstance().get(API.LESSON_ALL, {
    params: data,
  })
